import React from 'react'
import Layout from '../components/layout-v2';


const Page = () => (

  <Layout title="Data Science">
    <section>
      <p>
        A boutique AI technology company focused on providing best-in-class solutions in ML and AI specializing in Computer Vision, NLP and Reinforcement
        Learning Motivated by Akaike Information Criterion we, as a company believe in delivering efficient AI models that provide maximum impact.
      </p>
      <p>
        Deep learning experts in Computer vision and Natural language processing
        Derive actionable insights from unstructured data
        Language processing expertise in intent extraction, chatbots, natural querying
        AI-enabled process automation
        High throughput inspection with Machine vision
      </p>
      <ul>
        <li>
          Surveillance
          <ul>
            <li>People tracking</li>
            <li>Face recognition</li>
            <li>Vehicle tracking</li>
            <li>Hand gesture control</li>
          </ul>
        </li>
        <li>
          Retail
          <ul>
            <li>Out of stock detection</li>
            <li>In-store customer analytics</li>
          </ul>
        </li>
        <li>
          Healthcare
          <ul>
            <li>Medical imaging</li>
            <li>Disease modeling</li>
            <li>NLP based drug repurposing</li>
            <li>Revenue leakage in hospitals</li>
          </ul>
        </li>

        <li>
          Finance
          <ul>
            <li>Optimal order execution</li>
            <li>Portfolio optimization</li>
          </ul>
        </li>
        <li>
          e-Commerce
          <ul>
            <li>Product recommendation</li>
            <li>Advertising optimization</li>
            <li>Fraud detection</li>
          </ul>
        </li>
      </ul>

      <br/>
      <br/>
    </section>
  </Layout>
);

export default Page;
